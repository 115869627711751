<template>
    <div class="box">
    	<div class="container-fluid">
	        <div class="mb-3">
	            <button class="btn btn-primary" @click="scan">
	                <template v-if="scanning">
	                    <font-awesome-icon :icon="['fal', 'spinner']" spin /> {{ $t('global.bluetooth_active') }}
	                </template>
	                <template v-else>
	                    {{ $t('global.activer_bleutooth') }}
	                </template>
	            </button>
	        </div>

	        <textarea class="w-100 form-control" rows="5" v-model="transpondeurs" :placeholder="getTrad('horse.transpondeur_par_ligne')"></textarea>
	        
	        <div class="text-center mt-3">
	            <button class="btn btn-primary" @click="get_horse">
	                <font-awesome-icon v-if="loading" :icon="['fal', 'spinner']" spin /><font-awesome-icon v-else :icon="['far', 'plus']" /> {{ $t('global.ajouter') }}
	            </button>
	        </div>
	    </div>
    </div>
</template>

<script type="text/javascript">
import HorseMixin from "@/mixins/Horse.js";

export default {
    name: "Microship",
    mixins: [HorseMixin],
    data (){
        return {
            transpondeurs: '',
            scanning: false,
			loading: false,
            device: null
        }
    },
    methods: {
        scan() {
            let that = this
            try {
                if(that.device != null) {
                    that.device.gatt.disconnect()
                    that.device = null
                    that.scanning = false
                }

                navigator.bluetooth.requestDevice({
                    optionalServices: ['0000ffe0-0000-1000-8000-00805f9b34fb'],
                    acceptAllDevices: true
                })
                .then(device => {
                    that.device = device
                    return device.gatt.connect()
                })
                .then(server => {
                    return server.getPrimaryService('0000ffe0-0000-1000-8000-00805f9b34fb')
                })
                .then(service => {
                    return service.getCharacteristic('0000ffe1-0000-1000-8000-00805f9b34fb')
                })
                .then(characteristic => {
                    return characteristic.startNotifications()
                })
                .then(characteristic => {
                    characteristic.addEventListener('characteristicvaluechanged', (event) => {
                        let enc = new TextDecoder('windows-1258')
                        enc = enc.decode(event.target.value)
                        const regex = /([0-9]{3} [0-9]{12})/
                        let str = null
                        if((str = regex.exec(enc)) !== null) {
                            const new_line =  '\r\n'
                            let temp = that.transpondeurs.trim() + new_line + str[0].replace(' ', '').trim()
                            that.transpondeurs = temp.trim() + new_line
                        }
                    })
                    that.scanning = true
                })
                .catch(error => {
                    this.failureToast("toast.enable_bluetooth_error")
                });
            } catch(e) {
                this.failureToast("toast.enable_bluetooth_error")
            }
        },
        async get_horse() {
            if(!this.loading) {
                this.loading = true
                const transpondeurs = this.transpondeurs.split(/\r\n|\n|\r/);
                if(transpondeurs.length > 50) {
                    this.failureToast("toast.transpondeur_nb_error")
                    this.loading = false
                    return
                }

                const result = await this.addHorseByTranspondeur(this.transpondeurs)
                if(!result) {
                    this.failureToast("toast.info_save_failed")
                    this.loading = false
                    return
                }

                const errors = result.retour.errors
                if(errors.length > 0) {
                    this.failureToast(this.getTrad("toast.add_horse_transpondeur_error") + ' ' + errors.join(', '))
                    const ids = result.retour.ids
                    if(ids.length > 0) {
                        await this.$sync.force() 
                        this.$router.push({ name: 'postImportHorseList', params: { horse_ids: ids }})
                    }
                    this.loading = false
                    return
                }
                
                this.successToast("toast.info_save_succes")
                
                const ids = result.retour.ids
                if(ids.length > 0) {
                    await this.$sync.force() 
                    this.$router.push({ name: 'postImportHorseList', params: { horse_ids: ids }})
                }
                this.loading = false
            }
        }
    },
    components: {
        HeaderTab: () => import('@/components/HeaderTab')
    }
}
</script>